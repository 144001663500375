var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grand-child-pool childpool-wrap"},[_c('CCard',[_c('CCardBody',[_c('CDataTable',{staticClass:"mb-0",attrs:{"outlined":"","striped":"","hover":"","items":_vm.partners,"fields":_vm.tableHeaders,"head-color":"light","no-sorting":""},scopedSlots:_vm._u([{key:"order",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{staticClass:"text-center"},[_vm._v(_vm._s(index + 1))])}},{key:"partnerName",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('span',{staticClass:"link-go-to",on:{"click":function($event){return _vm.goTo(item.id)}}},[_vm._v(_vm._s(item.name))])])}},{key:"createAt",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("moment")(item.created_at,"YYYY-MM-DD HH:mm")))])}}])}),_c('div',[(_vm.pages>1)?_c('CPagination',{attrs:{"activePage":_vm.activePage,"pages":_vm.pages,"align":"end"},on:{"update:activePage":[function($event){_vm.activePage=$event},_vm.pushPage],"update:active-page":function($event){_vm.activePage=$event}}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }