<template>
  <div class="grand-child-pool childpool-wrap">
    <CCard>
      <CCardBody>
        <CDataTable
          class="mb-0"
          outlined
          striped
          hover
          :items="partners"
          :fields="tableHeaders"
          head-color="light"
          no-sorting
        >
          <td class="text-center" slot="order" slot-scope="{ item, index }">{{ index + 1 }}</td>

          <td class="text-center" slot="partnerName" slot-scope="{ item }">
            <span class="link-go-to" @click="goTo(item.id)">{{ item.name }}</span>
          </td>
          <td
            class="text-center"
            slot="createAt"
            slot-scope="{ item }"
          >{{ item.created_at | moment("YYYY-MM-DD HH:mm") }}</td>
        </CDataTable>
        <div>
          <CPagination
            v-if="pages>1"
            :activePage.sync="activePage"
            :pages="pages"
            align="end"
            @update:activePage="pushPage"
          />
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import endpoints from "@/constants/endpoints";
export default {
  name: "GrandChildPool",
  data() {
    return {
      tableHeaders: [
        { key: "order", label: "#", _classes: "text-center" },
        { key: "partnerName", label: this.$t("GRAND_CHILDPOOL_PAGE_LABEL_PARTNER_NAME"), _classes: "text-center" },
        { key: "createAt", label: this.$t("GRAND_CHILDPOOL_PAGE_LABEL_CREATED_AT"), _classes: "text-center" }
      ],
      partnerID: "",
      partners: [],
      limit: 2,
      activePage: 1,
      pages: 0
    };
  },

  computed: {
    offset() {
      return (this.activePage - 1) * this.limit;
    }
  },
  async mounted() {
    this.partnerID = this.$route.params.id;
    let child = await this.getListChild();
    if (!child) {
      return;
    }

    this.partners = child.items.slice();
    let totalItems = parseInt(child.total);
    this.pages = parseInt(totalItems / (this.limit + 0.5)) + 1;
  },
  methods: {
    async getListChild() {
      try {
        const params = {
          limit: this.limit,
          offset: this.offset
        };
        let result = await this.$http.get(
          endpoints.getGrandChild(this.partnerID),
          {
            params: params
          }
        );
        return result.data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    async pushPage() {
      let child = await this.getListChild();
      if (!child) {
        return;
      }
      this.partners = child.items.slice();
    },

    goTo(id) {
      this.$router.push(`transaction-memo`);
    }
  }
};
</script>
<style lang="scss" scoped>
.grand-child-pool {
  .link-go-to {
    cursor: pointer;
    &:hover {
      color: #0000ff;
    }
  }
}
</style>
